<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="supplierDebtsList.data && supplierDebtsList.data.length > 0 ? '400' : ''"
              :style="{ maxHeight: !supplierDebtsList.data ? '470px' : '' }"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="!isFocused ? headers : focusedHeaders"
              :items="!isFocused ? invoiceData : supplierDebtsList.data"
              :item-class="dynamicColor"
              fixed-header
              :search="search"
              :loading="isProgressCircular"
              @pagination="newPagination"
              @page-count="pageCount = $event"
              :items-per-page="limitData"
              :page.sync="page"
              @dblclick:row="doubleClick"
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                </v-overlay>
              </template>
              <template v-slot:top>
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">{{ $t("debitReport.filters") }}</h6>
                  <v-spacer />
                  <HtmlToPdf class="me-2 mb-1" :disabled="!!(supplierDebtsList.data && supplierDebtsList.data.length > 0)" :model="supplierDebtsList" :isFocused="isFocused" :payload="payload" type="SupplierDebtsReport"/>
                  <ExcelAndResetFilterCommon :data="(supplierDebtsList.data && supplierDebtsList.data.length > 0)" @downloadExcelFile="downloadExcelFile" @resetFilters="resetFilters"/>
                </v-card-title>
                <v-divider />
                <v-form v-model="valid" ref="form">
                  <v-card-text>
                    <v-row :dense="true">
                      <v-col cols="5">
                        <v-row :dense="true">
                          <v-col cols="12" class="d-flex">
                            <v-menu
                              ref="invoiceStartDateMenu"
                              v-model="invoiceStartDateMenu"
                              :close-on-content-click="false"
                              :return-value.sync="invoice_start_date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  hide-details="auto"
                                  dense
                                  class="me-2"
                                  v-model="formattedFromDate"
                                  :label="$t('debitReport.fromInvoiceDate')"
                                  @input="buildUrl"
                                  readonly
                                  :rules="profileById.date_format ? dateValid : dateValid2"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="invoice_start_date"
                                @input="buildUrl"
                                @change="$refs.invoiceStartDateMenu.save(invoice_start_date)"
                              >
                                <v-btn
                                  small
                                  class="primary"
                                  @click="$refs.invoiceStartDateMenu.save(todayDate())"
                                  >{{ $t("route.today") }}</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                            <v-menu
                              ref="invoiceEndDateMenu"
                              v-model="invoiceEndDateMenu"
                              :close-on-content-click="false"
                              :return-value.sync="invoice_end_date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  hide-details="auto"
                                  dense
                                  v-model="formattedToDate"
                                  :label="$t('debitReport.toInvoiceDate')"
                                  @input="buildUrl"
                                  readonly
                                  :rules="profileById.date_format ? dateValid : dateValid2"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :min="invoice_start_date ? invoice_start_date : ''"
                                v-model="invoice_end_date"
                                @input="buildUrl"
                                @change="$refs.invoiceEndDateMenu.save(invoice_end_date)"
                              >
                                <v-btn
                                  small
                                  class="primary"
                                  @click="$refs.invoiceEndDateMenu.save(todayDate())"
                                  >{{ $t("route.today") }}</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" class="d-flex">
                            <v-menu
                              ref="paymentStartDateMenu"
                              v-model="paymentStartDateMenu"
                              :close-on-content-click="false"
                              :return-value.sync="payment_start_date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  hide-details="auto"
                                  class="me-2"
                                  v-model="formattedPaymentFromDate"
                                  :label="$t('debitReport.fromPaymentDate')"
                                  @input="buildUrl"
                                  readonly
                                  :rules="profileById.date_format ? dateValid : dateValid2"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="payment_start_date"
                                @input="buildUrl"
                                @change="$refs.paymentStartDateMenu.save(payment_start_date)"
                              >
                                <v-btn
                                  small
                                  class="primary"
                                  @click="$refs.paymentStartDateMenu.save(todayDate())"
                                  >{{ $t("route.today") }}</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                            <v-menu
                              ref="paymentEndDateMenu"
                              v-model="paymentEndDateMenu"
                              :close-on-content-click="false"
                              :return-value.sync="payment_end_date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  hide-details="auto"
                                  v-model="formattedPaymentToDate"
                                  :label="$t('debitReport.toPaymentDate')"
                                  @input="buildUrl"
                                  readonly
                                  :rules="profileById.date_format ? dateValid : dateValid2"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :min="payment_start_date ? payment_start_date : ''"
                                v-model="payment_end_date"
                                @input="buildUrl"
                                @change="$refs.paymentEndDateMenu.save(payment_end_date)"
                              >
                                <v-btn
                                  small
                                  class="primary"
                                  @click="$refs.paymentEndDateMenu.save(todayDate())"
                                  >{{ $t("route.today") }}</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1"> </v-col>
                      <v-col cols="6">
                        <v-row :dense="true">
                          <v-col cols="6">
                            <v-autocomplete
                              autocomplete="new-password"
                              dense
                              cache-items
                              v-model="selectedSupplier"
                              :items="supplierList.data"
                              item-text="name_1"
                              item-value="id"
                              flat
                              return-object
                              hide-no-data
                              hide-details="auto"
                              single-line
                              clearable
                              @change="onSupplierChange"
                              @click:clear="onSupplierChange"
                              :search-input.sync="supplierSearch"
                              :label="$t('supplier.supplier')"
                            >
                              <template v-slot:append-outer>
                                <v-icon color="primary" dense @click="supplierDialog = true">mdi-magnify</v-icon>
                              </template>
                              <template v-slot:selection="data">
                                <span>{{`${data.item.id}. ${data.item.name_1}`}}</span>
                              </template>
                              <template v-slot:item="data">
                                <div class="font-weight-medium" style="font-size: 0.8125rem;display: flex;align-item: center;">
                                  {{ `${data.item.id}. ${data.item.name_1}` }}
                                </div>
                              </template>
                              <template v-slot:append-item>
                                <div v-show="hasSupplierNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                                  <v-progress-circular indeterminate color="primary"/>
                                </div>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete
                              hide-details="auto"
                              dense
                              v-model="selectedSupplierGroup"
                              :items="supplierGroupValue"
                              item-text="description"
                              item-value="id"
                              clearable
                              flat
                              @input="buildUrl"
                              return-object
                              :label="$t('supplierCreditInvoice.supplierGroup')"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              hide-details="auto"
                              v-model="selectedRegion"
                              :items="regionValue"
                              item-text="description"
                              item-value="id"
                              return-object
                              clearable
                              flat
                              :menu-props="{ bottom: true, offsetY: true }"
                              @input="buildUrl"
                              :label="$t('debitReport.area')"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete
                              autocomplete="new-password"
                              v-model="selectedEmployee"
                              cache-items
                              :items="employeeList.data"
                              item-text="name"
                              item-value="id"
                              flat
                              return-object
                              hide-no-data
                              hide-details="auto"
                              single-line
                              clearable
                              @change="onEmployeeChange"
                              @click:clear="onEmployeeChange"
                              :search-input.sync="employeeSearch"
                              :label="$t('debitReport.agent')"
                            >
                              <template v-slot:append-outer>
                                <v-icon class="mt-1" color="primary" dense @click="employeeDialog = true">
                                  mdi-magnify
                                </v-icon>
                              </template>
                              <template v-slot:selection="data">
                                <span>{{`${data.item.id}. ${data.item.name}`}}</span>
                              </template>
                              <template v-slot:item="data">
                                <div class="font-weight-medium" style="font-size: 0.8125rem;display: flex;align-item: center;">
                                  {{ `${data.item.id}. ${data.item.name}` }}
                                </div>
                              </template>
                              <template v-slot:append-item>
                                <div v-show="hasEmployeeNextPage" v-intersect="employeeInfiniteScroll" ref="load" class="loader text-center">
                                  <v-progress-circular indeterminate color="primary"/>
                                </div>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row :dense="true" class="my-5 mx-2">
                      <v-col cols="12">
                        <v-row>
                          <v-checkbox disabled :dense="true" v-model="invoice" :label="$t('route.supplierInvoice')"/>
                          <v-checkbox class="mx-8" :dense="true" v-model="creditInvoice" color="info" :label="$t('route.supplierCreditInvoice')"/>
                          <v-checkbox :dense="true" v-model="receipts" color="warning" :label="$t('route.supplierPayment')"/>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" class="text-center mt-3">
                        <ExecuteAndRestCommon :valid="valid" @reset="$store.commit('supplierDebts/SET_SUPPLIER_DEBTS',[])" @paginate="paginate(false)" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="6" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">{{ $t("route.supplierDebts") }}</h6>
                    </v-col>
                    <v-col cols="4" class="font-weight-regular">
                      <v-switch hide-details="auto" v-model="isFocused" color="primary" class="float-end mt-0 me-2">
                        <template v-slot:label>
                          <span :class="isFocused ? 'primary--text' : ''">{{$t("debitReport.focused")}}</span>
                        </template>
                      </v-switch>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">{{$t("common.noDataFound")}}</v-card-text>
              </template>
              <template v-slot:[`item.id`]="props">
                <span :class="!props.item.supplier_name ? 'font-weight-bold text-no-wrap' : ''">{{ props.item.id }}</span>
              </template>
              <template v-slot:[`item.type`]="props">
                <span v-if="props.item.supplier_id">
                  {{props.item.type ? typeHebrew.includes(props.item.type) ? $t(`debitReport.${props.item.type}`) : "" : $t("route.invoice")}}
                </span>
              </template>
              <template v-slot:[`item.invoice_date`]="props">
                <span>{{props.item.invoice_date && (profileById.date_format ? formatDateDDMMYYYY(new Date(props.item.invoice_date).toISOString().substring(0, 10)): new Date(props.item.invoice_date).toISOString().substring(0, 10))}}</span>
              </template>
              <template v-slot:[`item.doc_number`]="props">
                <span>{{props.item.doc_number ? props.item.doc_number : props.item.id}}</span>
              </template>
              <!-- <template v-slot:[`item.payment_date`]="props">
                <span>{{props.item.payment_date && (profileById.date_format ? formatDateDDMMYYYY(new Date(props.item.payment_date).toISOString().substring(0, 10)): new Date(props.item.payment_date).toISOString().substring(0, 10))}}</span>
              </template> -->
              <template v-slot:[`item.grand_total`]="props">
                <span v-if="accessRight.includes('financial')" style="direction: ltr" :class="!props.item.supplier_name ? 'font-weight-bold' : ''" class="d-inline-block">{{ numberWithCommas(props.item.grand_total) }}</span>
              </template>
              <template v-slot:[`item.balance`]="props">
                <span v-if="accessRight.includes('financial')" style="direction: ltr" :class="!props.item.supplier_name ? 'font-weight-bold' : ''" class="d-inline-block">{{ numberWithCommas(props.item.balance) }}</span>
              </template>
              <template v-slot:[`item.total`]="props">
                <span v-if="accessRight.includes('financial')" style="direction: ltr" class="d-inline-block">{{props.item.total ? numberWithCommas(props.item.total) : ""}}</span>
              </template>
              <template v-slot:[`item.balanceTotal`]="props">
                <span v-if="accessRight.includes('financial')" style="direction: ltr" class="d-inline-block">{{ numberWithCommas(props.item.balanceTotal) }}</span>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        :length="supplierDebtsList.data ? pageCount : 0"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
            <v-divider />
            <v-row>
              <v-col class="mx-auto" cols="8" v-if="!isFocused">
                <span class="ps-3 pt-2 text-caption">{{ $t("common.numberOfTotalRows")}}{{supplierDebtsList && supplierDebtsList.totalRows ? supplierDebtsList.totalRows : 0}}</span>
              </v-col>
              <v-col class="mx-auto" cols="8" v-else>
                <span class="ps-3 pt-2 text-caption">{{ $t("common.numberOfTotalRows")}}{{supplierDebtsList && supplierDebtsList.isFocusedTotalRows ? supplierDebtsList.isFocusedTotalRows : 0}}</span>
              </v-col>
              <v-col cols="4">
                <v-row dense v-if="accessRight.includes('financial')">
                  <v-col cols="6" class="text-end font-weight-bold">
                    <span class="ps-6 pt-2 text-body-2 font-weight-bold">{{$t("invoice.total")}}</span>
                  </v-col>
                  <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                    <span class="pe-6 pt-2 text-body-2 font-weight-bold">
                      {{supplierDebtsList && supplierDebtsList.allbalanceAmountTotal ? numberWithCommas(supplierDebtsList.allbalanceAmountTotal) : 0}}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <EmployeeDialog @onEmployeeChange="onEmployeeChange" :dialog.sync="employeeDialog"/>
      <SupplierDialog @onSupplierChange="onSupplierChange" :dialog.sync="supplierDialog"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName } from "@/utils";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import { formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate } from "@/utils";
import SupplierDialog from "@/components/moduleTableDialog/Dialog/SupplierDialog";
import EmployeeDialog from "@/components/moduleTableDialog/Dialog/EmployeeDialog";
import exportFromJSON from "export-from-json";
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from "@/components/ExecuteAndRestCommon";
import { validationMixin } from '@/mixins/validationMixin';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
  name: "SupplierDebtsReport",
  components: {
    ListSkeleton,
    HtmlToPdf,
    EmployeeDialog,
    SupplierDialog,
    ExcelAndResetFilterCommon,
    ExecuteAndRestCommon
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      sortByValue: null,
      sortDescValue: null,
      supplierPage: 1,
      supplierLimit: 10,
      employeePage: 1,
      employeeLimit: 10,
      limitData: this.limit,
      pagination: null,
      accessRights: [],
      employeeSearch: "",
      selectedSupplier: null,
      selectedSupplierGroup: null,
      selectedRegion: null,
      selectedEmployee: null,
      supplierGroupValue: [],
      regionValue: [],
      search: "",
      listSkeleton: false,
      invoiceStartDateMenu: false,
      invoiceEndDateMenu: false,
      paymentStartDateMenu: false,
      paymentEndDateMenu: false,
      invoice_start_date: "1990-01-01",
      invoice_end_date: "2099-12-31",
      payment_start_date: "1990-01-01",
      payment_end_date: "2099-12-31",
      isFocused: false,
      supplierDialog: false,
      employeeDialog: false,
      valid: false,
      custom: { id: 111111111111111, description: this.$t("invoice.empty") },
      numberOfRows: "",
      invoiceData: [],
      isProgressCircular: false,
      invoice: true,
      creditInvoice: false,
      receipts: false,
      typeHebrew: ["creditInvoice", "receipts"],
    };
  },
  computed: {
    formattedFromDate: {
      get() {
        return this.profileById.date_format ? formatDateDDMMYYYY(this.invoice_start_date) : parseDateYYYYMMDD(this.invoice_start_date);
      },
      set(val) {
        if (val.length == 10) {
          this.invoice_start_date = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
        }
      },
    },
    formattedToDate: {
      get() {
        return this.profileById.date_format ? formatDateDDMMYYYY(this.invoice_end_date) : parseDateYYYYMMDD(this.invoice_end_date);
      },
      set(val) {
        if (val.length == 10) {
          this.invoice_end_date = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
        }
      },
    },
    formattedPaymentFromDate: {
      get() {
        return this.profileById.date_format ? formatDateDDMMYYYY(this.payment_start_date) : parseDateYYYYMMDD(this.payment_start_date);
      },
      set(val) {
        if (val.length == 10) {
          this.payment_start_date = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
        }
      },
    },
    formattedPaymentToDate: {
      get() {
        return this.profileById.date_format ? formatDateDDMMYYYY(this.payment_end_date) : parseDateYYYYMMDD(this.payment_end_date);
      },
      set(val) {
        if (val.length == 10) {
          this.payment_end_date = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
        }
      },
    },
    statusList() {
      return [
        { name: this.$t("receipt.field.all"), id: null },
        { name: this.$t("receipt.field.open"), id: 0 },
        { name: this.$t("receipt.field.finished"), id: 1 },
        { name: this.$t("receipt.field.canceled"), id: 2 },
      ];
    },
    params() {
      if (this.pagination) {
        return {
          ...this.pagination,
          supplier_group_id: this.selectedSupplierGroup?.id,
          region_group_id: this.selectedRegion?.id,
          query: this.search,
          supplier_id: this.selectedSupplier?.id,
          employee_id: this.selectedEmployee?.id,
          invoice_start_date: this.invoice_start_date,
          invoice_end_date: this.invoice_end_date,
          payment_start_date: this.payment_start_date,
          payment_end_date: this.payment_end_date,
        };
      } else {
        return {};
      }
    },
    payload() {
      return {
        from_invoice_date: this.invoice_start_date,
        to_invoice_date: this.invoice_end_date,
        // start_payment_date: this.payment_start_date,
        // end_payment_date: this.payment_end_date,
        supplier_group_id: this.selectedSupplierGroup?.description,
        region_group_id: this.selectedRegion?.description,
        supplier_id: this.selectedSupplier?.name_1,
        employee_id: this.selectedEmployee?.name,
      };
    },
    ...mapGetters({
      supplierDebtsList: "supplierDebts/supplierDebtsList",
      supplierList: "supplier/supplierList",
      employeeList: "employee/employeeList",
      locale: "locale",
      accessRight: "accessRight",
      profileById: "profile/profileById",
      rowIndex: "supplierDebts/rowIndex",
    }),
    headers() {
      return [
        {text: this.$t("supplierInvoice.supplierNumber"),align: "start",value: "supplier_id",width: "125px",class: "px-1",sortable: false},
        {text: this.$t("supplier.supplierName"),value: "supplier_name",width: "140px",class: "px-2",sortable: false},
        {text: this.$t("debitReport.invoiceNumber"),value: "doc_number",width: "112px",class: "px-1",sortable: false},
        {text: this.$t("debitReport.invoiceDate"),value: "invoice_date",width: "112px",class: "px-2",sortable: false},
        // {text: this.$t("debitReport.paymentDate"),value: "payment_date",width: "112px",class: "px-2",sortable: false},
        {text: this.$t("debitReport.originalAmount"),value: "grand_total",width: "138px",sortable: false,class: this.accessRight.includes("financial") ? "" : "d-none"},
        {text: this.$t("debitReport.balance"),value: "balance",width: "95px",sortable: false,class: this.accessRight.includes("financial") ? "" : "d-none"},
        {text: this.$t("debitReport.cumulativeAmount"),value: "total",width: "156px",sortable: false,class: this.accessRight.includes("financial") ? "" : "d-none"},
      ];
    },
    focusedHeaders() {
      return [
        {text: this.$t("supplierInvoice.supplierNumber"),align: "start",value: "supplier_id",width: "125px",class: "px-1",sortable: false},
        {text: this.$t("supplier.supplierName"),value: "supplier_name",width: "140px",class: "px-2",sortable: false},
        {text: this.$t("debitReport.tel1"),value: "phone_1",width: "140px",class: "px-2",sortable: false},
        {text: this.$t("debitReport.cel1"),value: "cellular_1",width: "140px",class: "px-2",sortable: false},
        {text: this.$t("debitReport.balance"),value: "balanceTotal",width: "95px",sortable: true,class: this.accessRight.includes("financial") ? "" : "d-none"},
      ];
    },
    empty() {
      return this.$t("invoice.empty");
    },
  },
  watch: {
    empty() {
      this.custom.description = this.empty;
    },
    employeeSearch(val) {
      val ? this.$store.dispatch("employee/GetEmployee", {page: 1,limit: 10,where_like: val ? "name|" + val : val,order_by: "name|asc"}) : "";
    },
    supplierLimit() {
      this.$store.dispatch("supplier/GetSupplier", {page: 1,limit: this.supplierLimit,order_by: "name_1|asc"});
    },
    employeeLimit() {
      this.$store.dispatch("employee/GetEmployee", {page: 1,limit: this.employeeLimit,order_by: "name|asc"});
    },
    pagination: {
      handler(newVal) {
        if (this.pagination) {
          this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10;
        }
      },
      deep: true,
    },
    supplierDebtsList: {
      handler(newVal) {
        this.invoiceData = [];
        if (newVal.data && newVal.data.length > 0) {
          newVal.data.forEach((item) => {
            item.invoiceData.forEach((dd) => {
              this.invoiceData.push(dd);
            });
            this.invoiceData.push({
              id: item.supplier_name + " (" + item.supplier_id + ")",
              grand_total: item.originalAmountTotal,
              balance: item.balanceTotal,
              total: null,
            });
          });
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.listSkeleton = true;
    this.$store.commit("supplierDebts/SET_SUPPLIER_DEBTS_QUERY",this.$route.query);
    this.$store.dispatch("supplier/GetSupplier", {page: this.supplierPage,limit: this.supplierLimit,order_by: "name_1|asc"});
    this.$store.dispatch("employee/GetEmployee", {page: this.employeePage,limit: this.employeeLimit,order_by: "name|asc"});
    getAuxiliaryZtableValueByName(4).then((tableSupplierValue) => {
      if (Object.keys(tableSupplierValue).length > 0) {
        this.supplierGroupValue = tableSupplierValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.supplierGroupValue = [this.custom, ...this.supplierGroupValue];
      }
    });
    getAuxiliaryZtableValueByName(6).then((tableRegionNameValue) => {
      if (Object.keys(tableRegionNameValue).length > 0) {
        this.regionValue = tableRegionNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.regionValue = [this.custom, ...this.regionValue];
      }
    });
    this.listSkeleton = false;
  },
  destroyed() {
    this.$store.commit("supplierDebts/SET_SUPPLIER_DEBTS", []);
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    newPagination(val) {
      this.pagination = val;
      this.numberOfRows = val.itemsLength;
    },
    todayDate: todayDate,
    onEmployeeChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedEmployee = { id: item.id, name: item?.name };
        this.buildUrl();
      } else {
        this.selectedEmployee = null;
      }
    },
    async onSupplierChange(supplier) {
      if (supplier && typeof supplier === "object" && Object.keys(supplier).length > 0) {
        this.selectedSupplier = { id: supplier.id, name_1: supplier?.name_1 };
        this.buildUrl();
      } else {
        this.selectedSupplier = null;
      }
    },
    dynamicColor(item) {
      if (item.type === "creditInvoice") {
        return "credit-invoice-class";
      } else if (item.type === "receipts") {
        return "receipts-class";
      } else {
        return "";
      }
    },
    doubleClick(event, { item }) {
      if (!this.isFocused && /^\d+$/.test(item.id)) {
        if (item.type === "creditInvoice") {
          const path = "supplierCreditInvoice/supplierCreditInvoiceAction/" + item.id;
          this.accessRight.includes("edit") || this.accessRight.includes("show") ? window.open(path, "_blank") : "";
        } else if (item.type === "receipts") {
          const path = "supplierPayment/editSupplierPayment/" + item.id;
          this.accessRight.includes("edit") || this.accessRight.includes("show") ? window.open(path, "_blank") : "";
        } else {
          const path = "supplierInvoice/supplierInvoiceAction/" + item.id;
          this.accessRight.includes("edit") || this.accessRight.includes("show") ? window.open(path, "_blank") : "";
        }
        this.$store.commit("supplierDebts/SET_ROWINDEX", item.id);
      }
    },
    downloadExcelFile() {
      let self = this;
      if (this.isFocused === false) {
        let res = [];
        self.supplierDebtsList.data.forEach((el) => {
          el.invoiceData?.map((o) =>
            res.push(Object.fromEntries(self.headers.map((k) => [k.text, o[k.value]])))
          );
        });
        exportFromJSON({data: res,fileName: "supplierDebts",exportType: exportFromJSON.types.xls});
      } else if (this.isFocused === true) {
        let res = [];
        self.supplierDebtsList.data.forEach((el, index) => {
          let uniqueIds = [];
          el.invoiceData.forEach((element) => {
            let isDuplicate = uniqueIds.includes(element.supplier_id);
            if (!isDuplicate) {
              uniqueIds.push(element.supplier_id);
              res.push(
                Object.fromEntries(
                  self.focusedHeaders.map((k) => [k.text, element[k.value]])
                )
              );
            }
          });
          res[index][this.$t("debitReport.balance")] = el.balanceTotal;
        });
        exportFromJSON({data: res,fileName: "supplierDebts",exportType: exportFromJSON.types.xls});
      }
    },
    resetFilters() {
      this.sortByValue = null;
      this.sortDescValue = null;
      this.invoice_start_date = "1990-01-01";
      this.invoice_end_date = "2099-12-31";
      this.payment_start_date = "1990-01-01";
      this.payment_end_date = "2099-12-31";
      this.search = null;
      this.selectedSupplier = null;
      this.selectedSupplierGroup = null;
      this.selectedRegion = null;
      this.selectedEmployee = null;
      this.$store.commit("supplierDebts/SET_SUPPLIER_DEBTS_QUERY", {});
      this.buildUrl();
    },
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (0).toFixed(2);
    },
    getColor(status) {
      if (status == 0) return "orange";
      else if (status == 1) return "green";
      else return "red";
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        // this.page = 1;
        // obj.page = 1;
        // this.params.page = 1;
      }
      obj.suppId = this.selectedSupplier?.id;
      obj.empId = this.selectedEmployee?.id;
      obj.invoiceStartDate = this.invoice_start_date;
      obj.invoiceEndDate = this.invoice_end_date;
      obj.paymentStartDate = this.payment_start_date;
      obj.paymentEndDate = this.payment_end_date;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj.supplierGroupId = this.selectedSupplierGroup?.id;
      obj.regionGroupId = this.selectedRegion?.id;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "suppId" && val === null) return acc;
        else if (key === "supplierGroupId" && val === null) return acc;
        else if (key === "invoiceStartDate" && val === null) return acc;
        else if (key === "invoiceEndDate" && val === null) return acc;
        else if (key === "paymentStartDate" && val === null) return acc;
        else if (key === "paymentEndDate" && val === null) return acc;
        else if (key === "regionGroupId" && val === null) return acc;
        else if (key === "empId" && val === null) return acc;
        else if (key === "order_by" && val === null) return acc;
        else if (key === "sortDec" && val === null) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$store.commit("supplierDebts/SET_SUPPLIER_DEBTS_QUERY", obj);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supplierLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async employeeInfiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async paginate() {
      const {
        supplier_group_id,
        region_group_id,
        supplier_id,
        employee_id,
        invoice_start_date,
        invoice_end_date,
        // payment_start_date,
        // payment_end_date,
      } = this.params;

      const payloadData = {
        from_invoice_date: invoice_start_date,
        to_invoice_date: invoice_end_date,
        // start_payment_date: payment_start_date,
        // end_payment_date: payment_end_date,
        where: supplier_id ? "supplier_id|" + supplier_id : undefined,
        whereAnd: supplier_group_id ? "group_id|" + supplier_group_id : undefined,
        where_and: "",
        empty: supplier_group_id ? "group_id|" + "NULL" : undefined,
        empty_1: region_group_id ? "suppliers.region_id|" + "NULL" : undefined,
        type: this.creditInvoice ? this.receipts ? 'receipts,creditInvoice' : 'creditInvoice' : this.receipts ? 'receipts' : "",
      };

      if (supplier_group_id && supplier_group_id === 111111111111111) {
        payloadData.empty, delete payloadData.whereAnd;
      } else {
        payloadData.whereAnd, delete payloadData.empty;
      }

      let where_and_prefix = "";
      let where_and_sufix = "";

      if (region_group_id && region_group_id !== 111111111111111) {
        (where_and_prefix += "suppliers.region_id,"),
          (where_and_sufix += region_group_id + ",");
        delete payloadData.empty_1;
      }

      if (employee_id) {
        (where_and_prefix += "supplier_invoices.employee_id,"),
          (where_and_sufix += employee_id + ",");
      }

      where_and_prefix = where_and_prefix.replace(/.$/, "|");
      where_and_sufix = where_and_sufix.replace(/.$/, "");

      payloadData.where_and = where_and_prefix + where_and_sufix;

      this.isProgressCircular = true;
      // end make params for where_and
      await this.$store.dispatch("supplierDebts/GetSupplierDebtsReport", payloadData).then(() => {
        this.isProgressCircular = false;
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
::v-deep .credit-invoice-class {
  background-color: rgb(180, 212, 224) !important;
}
::v-deep .receipts-class {
  background-color: rgb(255, 201, 101) !important;
}
</style>
